var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        width: "60%",
        "append-to-body": "",
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c("div", { staticClass: "dia-tit not-title" }, [
        _c("i"),
        _c("span", [_vm._v("新增模板")]),
      ]),
      _c(
        "el-form",
        { attrs: { "label-width": "100px" } },
        [
          _c("el-form-item", { attrs: { label: "模板上传：" } }, [
            _c(
              "div",
              { staticStyle: { display: "flex" } },
              [
                _c(
                  "el-upload",
                  {
                    ref: "upload",
                    staticClass: "upload-demo",
                    attrs: {
                      "auto-upload": false,
                      "before-remove": _vm.beforeRemove,
                      "on-remove": _vm.handleAvatarRemove,
                      "file-list": _vm.fileList,
                      "on-success": _vm.handleAvatarSuccess,
                      "on-error": _vm.handleAvatarFail,
                      headers: _vm.headers,
                      "on-exceed": _vm.handleExceed,
                      accept: ".sb3",
                      action: _vm.uploadUrl,
                      "on-change": _vm.handleChange,
                      limit: 1,
                      name: "file",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          slot: "trigger",
                          size: "small",
                          type: "primary",
                        },
                        slot: "trigger",
                      },
                      [_vm._v("选取文件")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { size: "small", type: "success" },
                        on: {
                          click: function ($event) {
                            return _vm.submitUpload()
                          },
                        },
                      },
                      [_vm._v("上传到服务器")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "el-upload__tip",
                        attrs: { slot: "tip" },
                        slot: "tip",
                      },
                      [_vm._v("支持上传sb3文件！")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("el-row", { attrs: { slot: "footer" }, slot: "footer" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }