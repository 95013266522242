<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    :close-on-click-modal="false"
    width="60%"
    append-to-body
    top="7vh"
  >
    <div class="dia-tit not-title">
      <i></i>
      <span>新增模板</span>
    </div>
    <el-form  label-width="100px">
      <el-form-item label="模板上传：">
        <div style="display: flex">
          <el-upload
            :auto-upload="false"
            :before-remove="beforeRemove"
            :on-remove="handleAvatarRemove"
            :file-list="fileList"
            :on-success="handleAvatarSuccess"
            :on-error="handleAvatarFail"
            ref="upload"
            :headers="headers"
            :on-exceed="handleExceed"
            accept=".sb3"
            :action="uploadUrl"
            :on-change="handleChange"
            :limit="1"
            class="upload-demo"
            name="file"
          >
            <el-button size="small" slot="trigger" type="primary">选取文件</el-button>
            <el-button
              @click="submitUpload()"
              size="small"
              style="margin-left: 10px;"
              type="success"
            >上传到服务器</el-button>
            <div class="el-upload__tip" slot="tip">支持上传sb3文件！</div>
          </el-upload>
        </div>
      </el-form-item>
    </el-form>
    <el-row slot="footer">
    </el-row>
  </el-dialog>
</template>

<script>
  import { getToken } from '@/utils/auth'
  export default {
    props: {
      show: {
        default: false,
        type: Boolean
      },
    },
    data () {
      return {
        uploadUrl: process.env.NODE_ENV === 'development' ? '/api/srcTpl/upload' : process.env.VUE_APP_API_ADDRESS + 'srcTpl/upload',
        fileList:[],
        shouldChooseId:"",
        headers: {
          "Authorization":getToken()
        }
      }
    },
    methods: {
      submitUpload() {
      if (this.fileList.length === 0) {
          $msg("请先选择模板文件", 2);
          return false;
        } else {
          this.$refs.upload.submit();
        }
      },
      handleChange(file, fileList) {
        this.fileList = fileList.slice(-1);
      },
      handleAvatarFail(err, res, file) {
        $msg("上传文件失败", 2);
      },
      handleAvatarSuccess(res, file) {
        if (res && res.state == "success") {
          this.$refs.upload.clearFiles()
          this.shouldChooseId = res.body
          $msg("上传文件成功", 0);
        } else {
          $msg("上传文件失败", 2);
        }
      },

      handleExceed(files, fileList) {
        this.$message.warning(
          `当前限制选择 1 个文件，本次选择了 ${
            files.length
            } 个文件，共选择了 ${files.length + fileList.length} 个文件`
        );
      },
      handleAvatarRemove(file, fileList) {
        this.fileList = fileList;
      },
      beforeRemove(file, fileList) {
        return this.$confirm(`确定移除 ${file.name}？`);
      },
      openDialog() {
        this.shouldChooseId = ""
      },
      addScratchModule() {

      },
      close() {
        this.$emit("scratchmoduleList",this.shouldChooseId)
        this.$emit("close")
      },
    }
  }
</script>

<style lang="scss" scoped>
  /deep/ .el-dialog__body {
    padding: 0 !important;
  }
  .tag {
    display: inline-block;
    color: #fff;
    border-radius: 17px 20px 0px 17px;
    padding: 3px 15px;
    margin-right: 10px;
    margin-bottom: 8px;
  }
  @import "../../../style/dialog.scss";
</style>
